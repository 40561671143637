const isBrowser = typeof window !== "undefined"

export function isPhone() {
    if (!isBrowser) return
    return window.matchMedia('(max-width: 576px)').matches;
}

export function isSmallTablet() {
    if (!isBrowser) return
    return window.matchMedia('(max-width: 768px)').matches;
}

export function isTablet() {
    if (!isBrowser) return
    return window.matchMedia('(max-width: 1080px)').matches;
}

export function isSmallDesktop() {
    if (!isBrowser) return
    return window.matchMedia('(min-width: 1080px) and (max-width: 1400px)').matches;
}

export function isDesktop() {
    if (!isBrowser) return
    return window.matchMedia('(min-width: 1400px)').matches;
}