import * as React from "react"
import { useEffect, useState } from "react"

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Navigation } from "swiper";
import "swiper/css/navigation";
import templateCustom from '../components/template';
import './calendar.css'
import dataJSON from '../data/data.js'
import iconWhatsApp from '../assets/svg/icon-whats-black.svg'

import ReactMarkdown from 'react-markdown'

import { isSmallTablet, isTablet, isPhone } from '../common/mq.js';

 const isBrowser = typeof window !== "undefined"




const SectionComponent = (props) => {


  const [queryUrl, setQueryUrl] = useState(isBrowser?(new URLSearchParams(window.location.search)).get('units'):null)
  const [unitSelected, setUnitSelected] = useState({})

  function compileTemplate(){
    if(!isBrowser) return
    const compiler = require('vue-template-compiler');

    let template = undefined

    if(dataJSON.customer.tenant=='zedelivery'){
      template = templateCustom.templateDelivery.split('<br>').join('');
    }else{
      template = templateCustom.template.split('<br>').join('');
    }

    

    const compiledComponent = compiler.compileToFunctions(template);

    window.AngularEcommerce = window.AngularEcommerce || {};
    window.AngularEcommerce.web = window.AngularEcommerce.web || {};
    window.AngularEcommerce.web.templates = {
        events: {
            diferentao: compiledComponent
        }
    };
  }


  function getUnitByID(id){
    if (!isBrowser) return
    let interval = setTimeout(()=>{
      if(window.AngularEcommerce.web.listUnits.length){
        clearInterval(interval)
        if(window.AngularEcommerce.web.listUnits.filter(item=>item.id==id).length){

          let cnpj = window.AngularEcommerce.web.listUnits.filter(item=>item.id==id)[0].cnpj
    
          if(dataJSON.unit_customer.filter(item=>item.unit_cnpj==cnpj).length){
            setUnitSelected(dataJSON.unit_customer.filter(item=>item.unit_cnpj==cnpj)[0])
          }
    
        }
      }
      
    },1000)
    
  }


  function initCalendar(){
    let interval = setInterval(()=>{
      if(document.querySelector('ae-login-modal')){
        
        clearInterval(interval)

        compileTemplate()

        if(!document.querySelector('.angulare-schedule')) return 

        if(!document.querySelector('ae-weekly-calendar')){
          let el = document.createElement('ae-weekly-calendar')

          document.querySelector('.angulare-schedule').appendChild(el)


          //paramentros
          document.querySelector('ae-weekly-calendar').classList.add('order-first')
          document.querySelector('ae-weekly-calendar').isExternalFilters = true
          document.querySelector('ae-weekly-calendar').config = {
            days_in_schedule:7,
            alwaysShowUnitSelector: false,
            showImageInstructorInBoxEvent:true,
            showBorderLeftColorActivity:true,
            showFireIconLastVacancies:true,
            eventParams: {},
            useTodayAsFirstWeekday: props.data.st_schedule_type == 'session-from-monday' ? false : true
          }
          document.querySelector('ae-weekly-calendar').showEventsOnLaunchWithoutFilters = props.data.st_schedule_show_all_events ? true : false;
          document.querySelector('ae-weekly-calendar').eventTemplate = 'diferentao';

          document.querySelector('ae-weekly-calendar').isExternalFilters = true

          el.useLocationUnitActivitySelector = true
          el.typeExibitionActivities = 'radio'





          //eventos

          el.addEventListener('toMapPage', (e) => {
            //console.log(e)
          });

          el.addEventListener('toLogin', (e) => {
              document.querySelector('ae-login-modal').show = true
          });

          el.addEventListener('toCheckout', function () {
              if(document.querySelector('ae-checkout-modal')){
                  document.querySelector('ae-checkout-modal').open = true;
              }
              
          })

          el.addEventListener('toMapPage', function (data) {
            
              window.location.href = "http://localhost:9000/bikemap.html?&bikemap-event="+data.detail[0].event.token
          })

          el.addEventListener('toOnlineClassPage', function () {
              debugger
          })
        }
        
      }
    }, 1000)
  }

  function initPackage(){
    let interval = setInterval(()=>{
      if(document.querySelector('ae-login-modal')){
        
        clearInterval(interval)

        if(!document.querySelector('.angulare-package-list')) return 

        if(!document.querySelector('ae-package-list')){
          let el = document.createElement('ae-package-list')

          

          document.querySelector('.angulare-package-list').appendChild(el)


          //paramentros

          document.querySelector('ae-package-list').componentOld = false
          document.querySelector('ae-package-list').showinstallmentsLabel = true
          // document.querySelector('ae-weekly-calendar').isExternalFilters = true
          // document.querySelector('ae-weekly-calendar').config = {
          //   days_in_schedule:7,
          //   alwaysShowUnitSelector: false,
          //   showImageInstructorInBoxEvent:true,
          //   showBorderLeftColorActivity:true,
          //   showFireIconLastVacancies:true,
          //   eventParams: {},
          //   useTodayAsFirstWeekday: true
          // }
          // document.querySelector('ae-weekly-calendar').showEventsOnLaunchWithoutFilters = true;


          //eventos

          // el.addEventListener('toMapPage', (e) => {
          //   console.log(e)
          // });

          // el.addEventListener('toLogin', (e) => {
          //     document.querySelector('ae-login-modal').show = true
          // });

          el.addEventListener('toCheckout', function () {
              if(document.querySelector('ae-checkout-modal')){
                document.querySelector('ae-checkout-modal').open = true;
              }
              
          })

          // el.addEventListener('toMapPage', function (data) {
          //     window.location.href = "http://localhost:9000/bikemap.html?&bikemap-event="+data.detail[0].event.token
          // })

          // el.addEventListener('toOnlineClassPage', function () {
          //     debugger
          // })
        }
        
      }
    }, 1000)
  }


  function isCurrentSchedulePage(){
    if(!isBrowser) return
    if(dataJSON.app_pages.filter(it=>it.page_path==window.location.pathname).length){
      if(dataJSON.app_pages.filter(it=>it.page_path==window.location.pathname)[0].page_type=='schedule'){
        return true
      }

      return false
    }

    return false
    
  }

  (()=>{
    if (!isBrowser) return;
    window.addEventListener('unitChange', (e=>{
      setQueryUrl(e.detail)
      if(e.detail){
        getUnitByID(e.detail)
      }
    }))
  })()
  
  
  

  useEffect(()=>{
    //
    if(props.data.section_type === 'angulare-schedule'){
     initCalendar()
    }

    if(props.data.section_type === 'angulare-package-list'){
      initPackage()
    }
    

  }, [props, initCalendar, initPackage, queryUrl])

  
  return (
    <>
      <div className={ `flex flex-col order-${props.data.order}`}>



      {
          (()=>{
            if(props.data.section_type!=='only-text') return;
            return(
              <div className={"only-text w-full container"}>

                
                <div  className="text-justify mt-5" dangerouslySetInnerHTML={{__html: props.data.st_article_title }}></div>

                <div  className="text-justify mt-5" dangerouslySetInnerHTML={{__html:  props.data.st_article_description.replace(/\n/g, "<br />") }}></div>

              </div>
            )
          })()
        }


        {
          (()=>{
            if(props.data.section_type!=='article') return;
            return(
              <div className={"article w-full"}>
                <div className={`flex w-full gap-10 container ${props.data.st_article_layout=='single-column'?'flex-col':'flex-col md:flex-row'}`}>
                  <img 
                    style={{display: !isPhone()?'':'none'}} 
                    alt={props.data.section_type} 
                    className={props.data.st_article_layout==="side-left"?"order-3 w-full rounded":"order-1  w-full rounded"} 
                    src={ isPhone() ? props.data.st_article_image_mobile && props.data.st_article_image_mobile.length ? 'https://budibase.internal.angulare.app'+props.data.st_article_image_mobile[0].url : 'https://budibase.internal.angulare.app'+props.data.st_article_image_desktop[0].url : 'https://budibase.internal.angulare.app'+props.data.st_article_image_desktop[0].url} 
                  />

                  <img 
                    style={{display: isPhone()?'':'none'}} 
                    alt={props.data.section_type} 
                    className={props.data.st_article_layout==="side-left"?"order-3 w-full rounded":"order-3  w-full rounded"} 
                    src={ isPhone() ? props.data.st_article_image_mobile && props.data.st_article_image_mobile.length ? 'https://budibase.internal.angulare.app'+props.data.st_article_image_mobile[0].url : 'https://budibase.internal.angulare.app'+props.data.st_article_image_desktop[0].url : 'https://budibase.internal.angulare.app'+props.data.st_article_image_desktop[0].url} 
                  />


                <div 
                    style={{display:props.data.st_article_layout!='single-column'?'none':''}} 
                    className={props.data.st_article_cta_position=='side-left'?'flex  justify-center w-full order-3 justify-start ':'' + props.data.st_article_cta_position=='side-center'?'flex  justify-center w-full order-3 justify-center ':''}>
                  <a href={props.data.st_article_cta_url} style={{backgroundColor:'var(--primary)', width: "max-content", display:props.data.st_article_layout!='single-column'?'none':''}} className="text-white  px-3 py-2 mt-10 rounded hover:opacity-80 hover:no-underline grow-0">{props.data.st_article_cta_label}</a>
                </div>  

                <div 
                    style={{display:props.data.st_article_layout!='single-column'?'':'none'}} 
                    className={props.data.st_article_cta_position=='side-left'?'flex  justify-center w-full order-3 justify-start md:hidden ':'' + props.data.st_article_cta_position=='side-center'?'flex  justify-center w-full order-3 justify-center md:hidden':''}>
                  <a href={props.data.st_article_cta_url} style={{backgroundColor:'var(--primary)', width: "max-content", display:props.data.st_article_layout!='single-column'?'':'none'}} className="text-white  px-3 py-2 mt-10 rounded hover:opacity-80 hover:no-underline grow-0">{props.data.st_article_cta_label}</a>
                </div>  


                        

                  
                  {
                    (()=>{
                      if(props.data.st_article_layout!=='single-column'){
                        return(
                          <div className={props.data.st_article_layout==="side-left"?"order-1 w-full justify-center flex-col":"order-2 w-full flex justify-center flex-col"}>
                            {/* <h2 className="text-3xl font-bold"></h2> */}
                            <div  className="text-justify mt-5" dangerouslySetInnerHTML={{__html: props.data.st_article_title }}></div>
                            <div>
                              <ReactMarkdown>{props.data.st_article_description}</ReactMarkdown>
                            </div>
                            
                            <div className="mt-10 hidden md:block ">
                              <a href={props.data.st_article_cta_url} style={{backgroundColor:'var(--primary)', width: "max-content"}} className="text-white px-3 py-2 rounded hover:opacity-80 hover:no-underline grow-0">{props.data.st_article_cta_label}</a>
                            </div>
                            
                          </div>
                        )
                      }else{
                        return(
                          <div className={"w-full"}>

                            <div  className="text-justify mt-5" dangerouslySetInnerHTML={{__html: props.data.st_article_title }}></div>

                            <div  className="text-justify mt-5" dangerouslySetInnerHTML={{__html:  props.data.st_article_description.replace(/\n/g, "<br />") }}></div>

                            
                          </div>
                        )
                      }
                    })()
                  }
                  

                  
                </div>
              </div>
            )
          })()
        }


        {
          (()=>{
            if(props.data.section_type!=='angulare-package-list') return;
            return(
              <div className={"angulare-package-list w-full mt-0 md:mt-3 mb-5 "}></div>
            )
          })()
        }

        {
          (()=>{
            if(props.data.section_type!=='angulare-schedule') return;
            return(
              <div className={"angulare-schedule w-full mt-5 flex flex-col"}>

                <div style={{display:unitSelected.unit_name?'':'none'}} className="container order-last flex w-full justify-between gap-10 mt-5">
                  <div className="w-full">
                    <div className="w-full h-full bg-gray-100">
                      
                    </div>
                  </div>
                  <div className="w-full">
                    <h3 className="font-light">UNIDADE</h3>
                    <h3>{unitSelected.unit_name}</h3>

                    <p className="uppercase">{unitSelected.unit_address}</p>

                    <div>
                      <span className="font-semibold">Telefone:</span>
                      <p>{unitSelected.unit_mobile}</p>
                    </div>

                    <div>
                      <span className="font-semibold">E-mail:</span>
                      <p>{unitSelected.unit_email || 'Sem email'}</p>
                    </div>

                    <div >
                      <a href="#" className="flex gap-2 items-center cursor-pointer">
                        <img alt="" className="w-8 h-8 cursor-pointer" src={iconWhatsApp} />
                        <span>Clique aqui e fale conosco no whatsapp</span>
                      </a>
                    </div>
                    
                  </div>
                </div>
              </div>
            )
          })()
        }
        

        {
          (()=>{
            if(props.data.section_type!=='carroussel') return;
            return(
              (()=>{
                if(!isPhone()){

                
                  return(
                    <div className={"w-full"}>
                      <Swiper
                        className="w-full"
                        navigation={true} 
                        modules={[Navigation]} 
                        spaceBetween={50}
                        slidesPerView={1}
                        autoplay={true}
                      >

                        {
                          props.data.st_article_image_desktop.map((item, key)=>{
                            return(
                              <SwiperSlide key={key}>
                                <img 
                                    style={{width:'100%', height: isCurrentSchedulePage() ? '180px' : '500px'}} alt="ds" 
                                    className="rounded" 
                                    src={'https://budibase.internal.angulare.app'+item.url} 
                                  />
                              </SwiperSlide>
                            )
                          })
                        }
                        
                      </Swiper>
                    </div>
                  )
                }else{
                  return(
                    <div className={"w-full"}>
                      <Swiper
                        className="w-full"
                        navigation={true} 
                        modules={[Navigation]} 
                        spaceBetween={50}
                        slidesPerView={1}
                        autoplay={true}
                      >

                        {
                          props.data.st_article_image_mobile.map((item, key)=>{
                            return(
                              <SwiperSlide key={key}>
                                <img 
                                    style={{width:'100%', height:'485px'}} alt="ds" 
                                    className="rounded" 
                                    src={'https://budibase.internal.angulare.app'+item.url} 
                                  />
                              </SwiperSlide>
                            )
                          })
                        }
                        
                      </Swiper>
                    </div>
                  )
                } 
              })()
            )
          })()
        }
      </div>
    </>
    


  )
}

export default SectionComponent;
